import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
    Spinner,
} from "@chakra-ui/react";


export default function AdShow() {
    const [isShow, setIsShow] = useState(false)

    async function shad() {
        setTimeout(function() {
            setIsShow(true)
        }, 6000)
        
        setTimeout(function() {
            setIsShow(false)
        }, 9000)

        setInterval(function() {
            setIsShow(true)
            setTimeout(function() {
                setIsShow(false)
            }, 9000)
        }, 15000) 
    }

    useEffect(() => {
        shad()
    }, [])

    const countries = ["Argentina","Aruba","Australia","Austria","Bahamas","Bahrain","Barbados","Belarus","Belgium","Belize","Bermuda","Brazil","British Virgin Islands","Brunei","Bulgaria","Cape Verde","Cayman Islands","Chad","Chile","China","Colombia","Costa Rica","Cote D Ivoire","Croatia","Cuba","Cyprus","Czech Republic","Denmark","Djibouti","Dominica","Dominican Republic","Ecuador","Egypt","El Salvador","Equatorial Guinea","Estonia","Ethiopia","Falkland Islands","Faroe Islands","Fiji","Finland","France","French Polynesia","French West Indies","Georgia","Germany","Gibraltar","Greece","Greenland","Grenada","Guam","Guatemala","Guernsey","Haiti","Honduras","Hong Kong","Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland","Isle of Man","Israel","Italy","Jamaica","Japan","Jersey","Jordan","Kenya","Kuwait","Kyrgyz Republic","Laos","Latvia","Lebanon","Lesotho","Liechtenstein","Lithuania","Luxembourg","Macau","Macedonia","Madagascar","Malaysia","Maldives","Mali","Malta","Mauritania","Mauritius","Mexico","Moldova","Monaco","Montenegro","Montserrat","Morocco","Mozambique","Netherlands","Netherlands Antilles","New Caledonia","New Zealand","Nicaragua","Norway","Oman","Panama","Paraguay","Peru","Philippines","Poland","Portugal","Puerto Rico","Qatar","Romania","Russia","Saint Pierre & Miquelon","San Marino","Saudi Arabia","Serbia","Seychelles","Singapore","Slovakia","Slovenia","South Africa","South Korea","Spain","Sri Lanka","St Kitts & Nevis","St Lucia","St Vincent","St. Lucia","Swaziland","Sweden","Switzerland","Taiwan","Thailand","Timor L'Este","Trinidad & Tobago","Tunisia","Turkey","Turks & Caicos","Ukraine","United Arab Emirates","United Kingdom", "United States", "Uruguay","Uzbekistan","Venezuela","Vietnam","Virgin Islands (US)"];

    const action = ["recieved a reward of"]

    const price = [4500, 3809, 2578, 1800, 1456, 4890, 4900, 1500, 2500, 3500, 5000, 4789, 2745, 2600, 3600, 4200, 1200, 1400, 1300, 1700, 1100, 2200, 2400, 2600, 2800, 3700, 3800, 3400, 4200]

    const addr = ["0xfdf2 ... 517d", "0x836f ... 50cb", "0x4cda ... 1b15", "0x64c6 ... addd", "0x1ba6 ... 2dbd", "0xcc44 ... 84e0"]

    const isCountry = countries.sort(function () {
        return Math.random() - 0.5;
    })

    const isAction = action.sort(function () {
        return Math.random() - 0.5;
    })

    const isPrice = price.sort(function () {
        return Math.random() - 0.5;
    })

    const isAddr = addr.sort(function () {
        return Math.random() - 0.5;
    })


    return (
        <>
            {  
                isShow ?
                <Flex w={["80%", "auto"]} position="fixed" top="0" left="0" mt={["20%", "10%"]} ml={["2.5", "5%"]} data-aos="fade-right" bg="#fff" boxShadow="0px 5px 13px 0px rgba(0, 0, 0, .3)" padding="12px 27px" borderRadius="7px" zIndex="10">
                    <Text fontSize="14px"><Text as="span" fontWeight="bold">{isAddr[0]}</Text> from <Text as="span" fontWeight="bold">{isCountry[0]}</Text> just <Text as="span" fontWeight="bold">{isAction[0]}</Text> <Text as="span" fontWeight="bold" color="#009442">${isPrice[0].toLocaleString()}</Text></Text>
                </Flex>
                :
                null
            }
        </>
    )   
}