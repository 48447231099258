import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
    Spinner,
} from "@chakra-ui/react";


export default function CdShow() {
    const [days, setDays] = useState(0)
	const [hours, setHours] = useState(0)
	const [minutes, setMinutes] = useState(0)
	const [seconds, setSeconds] = useState(0)
	const [tEnd, setTend] = useState(false)
	
	useEffect(() => {
		var countDownDate = new Date("Jun 23, 2024 00:00:00").getTime();
		// Run myfunc every second
		var myfunc = setInterval(function() {
			var now = new Date().getTime();
			var timeleft = countDownDate - now;
			// Calculating the days, hours, minutes and seconds left
			var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
			var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((timeleft % (1000 * 60)) / 1000); 
			// Result is output to the specific element
			setDays(days)
			setHours(hours.toString().length < 2 ? "0"+hours : hours)
			setMinutes(minutes.toString().length < 2 ? "0"+minutes : minutes)
			setSeconds(seconds.toString().length < 2 ? "0"+seconds : seconds)  
			// Display the message when countdown is over
			if (timeleft < 0) {
				clearInterval(myfunc);
				setDays("")
				setHours("")
				setMinutes("")
				setSeconds("")
				setTend(true)
			}
		}, 100);
	}, [])

    return (
        <>
            <Text fontSize={["13px", "15px"]} fontWeight="800">Rewards Eligibity Countdown</Text>
            {
                tEnd ? 
                <Text fontSize={["30px", "40px"]} fontWeight="800">Rewards has ended</Text>
                :
                <Text fontSize={["30px", "40px"]} fontWeight="800">{days} {days === 1 ? "day" : "days"}, <Text display={["block", "inline"]}>{hours}h:{minutes}m:{seconds}s</Text></Text>
            }
        </>
    )
}